import React, {
    useContext,
  } from 'react'  
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    LegacyCard,
    Icon,
    LegacyStack,
    ButtonGroup,
    Button,
    TextContainer,
    FormLayout,
    Text,
} from "@shopify/polaris";
import { ExternalSmallIcon, ExternalIcon } from "@shopify/polaris-icons";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import OnboardingForNew from '../../../components/onBoardingForNew';

const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        max-width: 575px;
        margin: auto;
        .instructions-image {
            margin-bottom: 20px;
            padding: 10px;
            div {
                img {
                    max-width: 125px;
                }
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`
const ButtonsWrapper = styled.div`
    margin-top: 15px;
`
const OfferButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: center;
`
function CartDrawerOfferPreview(props) {
    const { location } = props
    const from = location && location.state && location.state.from
    const { firebase, shop, token } = useContext(FirebaseContext);
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
        shopDoc,
        {
            transform: (snap) => snap.shopData
        }
    );
    return (
        <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate("/app/offers")
                }}} 
                title="Create Cart Drawer Upsell">
         {from ? 
        <OnboardingForNew initStep={3} extensionName={"Cart upsell"} cartUpsellName={"Cart Drawer Upsell"}/>
        :            
        <Layout>
            <Layout.Section>
                {/*
                <div style={{margin: '20px 0 10px -25px'}}>
                    <CreateOfferSteps step={3}/>
                </div>
                */}
                <LegacyCard>
                <LegacyCard.Section>
                <CartOfferPreviewWrapper>
                    <div className='instructions'>
                        <div className="instructions-image">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-drawer-preview-icon.png?alt=media&token=edf63b96-8e47-443a-9019-6d8b1f6d19c2" />
                            </div>
                        </div>
                        <FormLayout>
                            <TextContainer spacing="loose">
                                <Text variant="headingXl" as="p">Connect your theme to UpsellPlus</Text>
                                <div>
                                <Text  variant="bodyMd" as="span" tone="subdued">This feature requires a technical setup. Request our support or follow the guide.</Text>
                                </div>
                            </TextContainer>
                        </FormLayout>
                    </div>
                    <div className='action-buttons'>
                          <ButtonsWrapper>
                          <LegacyStack distribution="center">
                                <ButtonGroup>
                                    <Button icon={ExternalIcon} onClick={
                                        () => {
                                            window.open(`https://help.upsellplus.com/en/article/how-to-get-started-cart-upsells-cart-drawer-apfde9/`, '_blank')
                                        }
                                    }>To setup guide
                                    </Button>
                                    <Button icon={ExternalIcon} onClick={
                                            () => {
                                                if(window && window.$crisp){
                                                    window.$crisp.push(["do", "chat:open"]);
                                                } 
                                            }
                                        }>Request setup
                                    </Button>
                                </ButtonGroup>
                            </LegacyStack>
                            </ButtonsWrapper>
                            <OfferButtonWrapper>
                                    <Button  variant="primary" onClick={
                                            () => {
                                                navigate('/app/offers', { replace: true,})
                                            }
                                        }>To offer overview</Button>
                            </OfferButtonWrapper>
                    </div>
                </CartOfferPreviewWrapper>
                </LegacyCard.Section>
                </LegacyCard>
            </Layout.Section>
        </Layout>
    }
    </Page>
    );
}

export default CartDrawerOfferPreview;